import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule} from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { ApartmentshortviewComponent } from './apartmentshortview/apartmentshortview.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { ApartmentinformationComponent } from './apartmentinformation/apartmentinformation.component';
import { ApartmentdetailComponent } from './apartmentdetail/apartmentdetail.component';
import { TextcomponentComponent } from './textcomponent/textcomponent.component';
import { PlayandfunComponent } from './playandfun/playandfun.component';
import { FarmandanimalsComponent } from './farmandanimals/farmandanimals.component';
import { AproachComponent } from './aproach/aproach.component';
import { ContactComponent } from './contact/contact.component';
import { HttpClientModule } from '@angular/common/http';
import { ImpressumComponent } from './impressum/impressum.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    FooterComponent,
    HomeComponent,
    ApartmentshortviewComponent,
    ApartmentsComponent,
    ApartmentinformationComponent,
    ApartmentdetailComponent,
    TextcomponentComponent,
    PlayandfunComponent,
    FarmandanimalsComponent,
    AproachComponent,
    ContactComponent,
    ImpressumComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
