import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-textcomponent',
  templateUrl: './textcomponent.component.html',
  styleUrls: ['./textcomponent.component.css']
})
export class TextcomponentComponent implements OnInit {
  @Input() content: String;
  constructor() { }

  ngOnInit() {
  }

}
