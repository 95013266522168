<div class="container-lg">
    <div class="row margin-top">
      <div class="col-sm-6 col-md-3">
        <a routerLink="/wohnungen/waldblick">
          <div class="card header-black custom-hoverable-card">
            <img src="../../assets/waldblick600.png" alt="Waldblick" class="card-img-top">
            <div class="card-body">
                <div class="card-text">
                    <ul class="padding-mobile">
                        <li>63 qm</li>
                        <li>2 Schlafzimmer</li>
                        <li>1 Bad</li>
                    </ul><br>
                    Preise für 2 Personen:<br><br>
                    <!--Hauptsaison:
                    <div class="apartment-prices">
                            50,00€
                    </div>
                    <div class="saison-information">
                          (27.03. - 10.04., 22.05. - 05.06., 26.06. - 11.09., 02.10. - 06.11., 18.12. - 08.01.)
                    </div>
                    <br>
                    <br><br><br>
                    Nebensaison: 
                    <div class="apartment-prices">
                      45,00€
                    </div>
                    <div class="saison-information">
                        (09.01. - 26.03., 11.04. - 21.05., 06.06. - 25.06., 12.09. - 01.10., 07.11. - 17.12.)
                    </div>
                    <br><br><br><br><br>
                    <div class="color-new-prices">Ab 01.01.2023 neue Preise:</div> <br>-->
                    Hauptsaison:
                    <div class="apartment-prices">
                          60,00€
                    </div>
                    <div class="saison-information">
                        (23.03. - 06.04., 18.05. - 01.06., 29.06. - 07.09., 28.09. - 02.11., 22.12. - 06.01.)
                    </div>
                  <br><br><br><br>
                  Nebensaison: 
                  <div class="apartment-prices">
                          55,00€
                  </div><div class="saison-information">
                    (07.01. - 22.03., 07.04. - 17.05., 02.06. - 28.06., 08.09. - 27.09., 03.11. - 21.12.)
                  </div>
                  <br>
                    <p><a class="btn btn-info" role="button">Mehr erfahren</a></p>
                </div>    
            </div>
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-3 margin-top-when-phone">
        <a routerLink="/wohnungen/talaue">
          <div class="card header-black custom-hoverable-thumbnail">
            <img src="../../assets/talaue600.png" alt="Talaue" class="card-img-top">
            <div class="card-body">
                <div class="card-text">
                    <ul class="padding-mobile">
                        <li>75 qm</li>
                        <li>2 Schlafzimmer</li>
                        <li>1 Bad</li>
                        <li>behindertengerecht</li>
                    </ul>
                    Preise für 2 Personen:<br><br>
                    <!--Hauptsaison:<div class="apartment-prices">
                            55,00€
                        </div><div class="saison-information">
                          (27.03. - 10.04., 22.05. - 05.06., 26.06. - 11.09., 02.10. - 06.11., 18.12. - 08.01.)
                          <br></div>
                    <br><br><br>
                    Nebensaison:<div class="apartment-prices">
                            50,00€
                        </div><div class="saison-information">
                          (09.01. - 26.03., 11.04. - 21.05., 06.06. - 25.06., 12.09. - 01.10., 07.11. - 17.12.)
                          <br></div>
                    <br><br><br><br><br>
                    <div class="color-new-prices">Ab 01.01.2023 neue Preise:</div> <br>-->
                    Hauptsaison:
                    <div class="apartment-prices">
                          65,00€
                    </div>
                    <div class="saison-information">
                      (23.03. - 06.04., 18.05. - 01.06., 29.06. - 07.09., 28.09. - 02.11., 22.12. - 06.01.)
                    </div>
                  <br><br><br><br>
                  Nebensaison: 
                  <div class="apartment-prices">
                          60,00€
                  </div><div class="saison-information">
                    (07.01. - 22.03., 07.04. - 17.05., 02.06. - 28.06., 08.09. - 27.09., 03.11. - 21.12.)
                  </div>
                    <p><a class="btn btn-info" role="button">Mehr erfahren</a></p>
                    </div>
                </div>
          </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-3 margin-top-when-phone">
        <a routerLink="/wohnungen/bergwiese">
          <div class="card header-black custom-hoverable-thumbnail">
            <img src="../../assets/bergwiese600.png" alt="Bergwiese" class="card-img-top">
            <div class="card-body">
                <div class="card-text">
                  <ul class="padding-mobile">
                    <li>68 qm</li>
                    <li>2 Schlafzimmer</li>
                    <li>1 Bad</li>
                    <li>behindertengerecht</li>
                </ul>
                Preise für 2 Personen:<br><br>
                <!--Hauptsaison: <div class="apartment-prices">
                        50,00€
                    </div>
                    <div class="saison-information">
                      (27.03. - 10.04., 22.05. - 05.06., 26.06. - 11.09., 02.10. - 06.11., 18.12. - 08.01.)
                      <br></div>
                <br><br><br>
                Nebensaison:<div class="apartment-prices">
                        45,00€
                    </div><div class="saison-information">
                      (09.01. - 26.03., 11.04. - 21.05., 06.06. - 25.06., 12.09. - 01.10., 07.11. - 17.12.)
                      <br></div> 
                <br><br><br><br><br>
                <div class="color-new-prices">Ab 01.01.2023 neue Preise:</div> <br>-->
                    Hauptsaison:
                    <div class="apartment-prices">
                          60,00€
                    </div>
                    <div class="saison-information">
                      (23.03. - 06.04., 18.05. - 01.06., 29.06. - 07.09., 28.09. - 02.11., 22.12. - 06.01.)
                    </div>
                  <br><br><br><br>
                  Nebensaison: 
                  <div class="apartment-prices">
                          55,00€
                  </div><div class="saison-information">
                    (07.01. - 22.03., 07.04. - 17.05., 02.06. - 28.06., 08.09. - 27.09., 03.11. - 21.12.)
                  </div>
                <p><a class="btn btn-info" role="button">Mehr erfahren</a></p>
                </div>
            </div>
        </div>
        </a>
      </div>
      <div class="col-sm-6 col-md-3 margin-top-when-phone">
        <a routerLink="/wohnungen/sonnenstrahl">
          <div class="card header-black custom-hoverable-thumbnail">
            <img src="../../assets/sonnenstrahl600.png" alt="Sonnenstrahl" class="card-img-top">
            <div class="card-body">
                <div class="card-text">
                  <ul class="padding-mobile">
                        <li>100 qm</li>
                        <li>3 Schlafzimmer</li>
                        <li>2 Bäder</li>
                        <li>Holzofen</li>
                    </ul>
                    Preise für 2 Personen:<br><br>
                    <!--Hauptsaison: <div class="apartment-prices">
                            75,00€
                        </div><div class="saison-information">
                          (27.03. - 10.04., 22.05. - 05.06., 26.06. - 11.09., 02.10. - 06.11., 18.12. - 08.01.)
                          <br></div>
                    <br><br><br>
                    Nebensaison: <div class="apartment-prices">
                            75,00€
                        </div><div class="saison-information">
                          (09.01. - 26.03., 11.04. - 21.05., 06.06. - 25.06., 12.09. - 01.10., 07.11. - 17.12.)
                          <br></div>
                    <br><br><br><br><br>
                    <div class="color-new-prices">Ab 01.01.2023 neue Preise:</div> <br>-->
                    Hauptsaison:
                    <div class="apartment-prices">
                          85,00€
                    </div>
                    <div class="saison-information">
                      (23.03. - 06.04., 18.05. - 01.06., 29.06. - 07.09., 28.09. - 02.11., 22.12. - 06.01.)
                    </div>
                  <br><br><br><br>
                  Nebensaison: 
                  <div class="apartment-prices">
                          85,00€
                  </div><div class="saison-information">
                    (07.01. - 22.03., 07.04. - 17.05., 02.06. - 28.06., 08.09. - 27.09., 03.11. - 21.12.)
                  </div>
                    <p><a class="btn btn-info" role="button">Mehr erfahren</a></p>
                    </div>
                </div>
          </div>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <iframe width="100%" height="1900px" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45040" title="Belegungskalender"><p>Ihr Browser kann das Kalender-Frame leider nicht anzeigen. Um den Kalender zu sehen klicken Sie bitte hier: <a href="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45040">https://api.belegungskalender-kostenlos.de/kalender.php?kid=45040</a></p></iframe><br /><span style="font-size:9px;"><a href="https://www.belegungskalender-kostenlos.de/" rel="nofollow" style="font-size:9px;">Kalender von belegungskalender-kostenlos.de</a></span>
      </div>
    </div>
  </div>
  
