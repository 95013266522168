import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ApartmentsComponent } from './apartments/apartments.component';
import { ApartmentdetailComponent } from './apartmentdetail/apartmentdetail.component';
import { PlayandfunComponent } from './playandfun/playandfun.component';
import { FarmandanimalsComponent } from './farmandanimals/farmandanimals.component';
import { AproachComponent } from './aproach/aproach.component';
import { ImpressumComponent } from './impressum/impressum.component';
import { ContactService } from './contact.service';
import { ContactComponent } from './contact/contact.component';


const routes: Routes = [
  {path: '', redirectTo: '/startseite', pathMatch: 'full'},
  {path: 'startseite', component: HomeComponent},
  {path: 'wohnungen', component: ApartmentsComponent},
  {path: 'wohnungen/:id', component: ApartmentdetailComponent},
  {path: 'spielundspass', component: PlayandfunComponent},
  {path: 'hofundtiere', component: FarmandanimalsComponent},
  {path: 'anfahrt', component: AproachComponent},
  {path: 'impressum', component: ImpressumComponent},
  {path: 'kontakt', component: ContactComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
