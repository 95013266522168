<div class="introHeader">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="introMessage">
            <h1 class="background-fix-h1">Glashof</h1>
            <h2 class="background-fix-h2">Herzlich Willkommen</h2>
            <h3 class="background-fix-h3">auf unserem Bio-Bauernhof</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row margin-top">
      <div class="offset-lg-2 col-lg-4 col-sm-6 col-md-6">
        4 & 5 Sterne Ferienwohnungen mit
        <ul>
          <li>Internetzugang (+ W-Lan)</li>
          <li>Brötchenservice</li>
          <li>Waschmaschine + Trockner</li>
          <li>großzügig ausgestatteter Küche inkl. Spülmaschine</li>
          <li>großer Spielzeugauswahl für Kinder</li>
        </ul>
      </div>
      <div class="col-lg-4 col-sm-6 col-md-6">
        kostenlose Konuskarte als Freifahrtticket für Busse und Bahnen in der Ferienregion Schwarzwald<br> <br>
        <a href="http://www.schwarzwald-tourismus.info/service/konus2" target="_blank"><button class="btn btn-info">Mehr Informationen</button></a>
      </div>
    </div>
  </div>
  <app-apartmentshortview></app-apartmentshortview>
  