import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Email } from './email';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private _contactUrl = 'https://glashof-schwarzwald.de/emailneu.php';
  constructor (private _http: HttpClient) {}

  postEmail(newMail: Email): Observable<Response> {
      let body = `name=${newMail.name}&street=${newMail.street}&zip=${newMail.zip}&city=${newMail.city}&email=${newMail.email}&phone=${newMail.phone}&from=${newMail.from}&until=${newMail.until}&subject=${newMail.subject}&message=${newMail.message}`;
      return this._http.post<Response>(this._contactUrl, body, httpOptions);

  }
}