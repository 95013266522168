export class Email {
    name: String;
    street: String;
    zip: String;
    city: String;
    email: String;
    phone: String;
    from: String;
    until: String;
    subject: String;
    message: String;

    constructor() {
        this.name = '';
        this.email = '';
        this.phone = '';
        this.from = '';
        this.until = '';
        this.subject = '';
        this.message = '';
    }
}

