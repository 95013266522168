import { FormControl, FormGroup, NgForm, FormGroupDirective } from '@angular/forms';

export class CustomEmailValidator {
  static areEqual(formGroup: FormGroup) {
    let value1 = formGroup.get('email').value;
    let value2 = formGroup.get('emailRepeat').value;
    if (value1 == value2) {
      return null;
    }

    return {
      areEqual: true
    };
  }
}