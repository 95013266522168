<div class="apartmentsHeader">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="introMessage">
                        <h1 class="background-fix-h1">Anfahrt</h1>
                        <h2 class="background-fix-h2">&nbsp;</h2>
                        <h3 class="background-fix-h3">&nbsp;</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row margin-top">
            <div class="offset-lg-2 col-lg-8 col-sm-offset-1 col-sm-10">
                <h2 class="custom-header">Hier finden Sie unseren Glashof im Schwarzwald</h2>
                <iframe src="https://www.google.com/maps/embed?pb=!1m21!1m12!1m3!1d286499.3006239519!2d8.0892263688803!3d48.153171945670024!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m6!3e2!4m0!4m3!3m2!1d48.1322216!2d8.3123717!5e0!3m2!1sde!2sde!4v1457785443726" width="100%" height="450" frameborder="0" style="border:0" allowfullscreen></iframe>
            </div>
        </div>
    </div>