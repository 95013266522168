<div class="apartmentsHeader">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="introMessage">
                    <h1 class="background-fix-h1">Kontakt</h1>
                    <h2 class="background-fix-h2">&nbsp;</h2>
                    <h3 class="background-fix-h3">&nbsp;</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container margin-top">
    <form id="contact-form" [formGroup]="emailForm" (ngSubmit)="onSubmit(emailForm.value)">
        <div class="row margin-top-5">
            <div class="offset-lg-1 col-lg-10">
                <h3 class="custom-header">Kontakt</h3>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputName" class="offset-lg-1 col-lg-3 control-label margin-top-5">Name*</label>
            <div class="col-lg-7 margin-top-5">
                <input type="text" class="form-control required" id="inputName" name="inputName" formControlName="name" placeholder="Name" required autocomplete="name"/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.name">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('name').hasError(validation.type) && (emailForm.get('name').dirty || emailForm.get('name').touched)">
                <div class="alert alert-danger">{{validation.message}}</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputStreet"class="offset-lg-1 col-lg-3 control-label margin-top-5">Straße*/Hausnummer*</label>
            <div class="col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputStreet" name="inputStreet" formControlName="street" placeholder="Straße" required="" autocomplete="address-line1"/>
            </div>
            <div class="offset-lg-1 col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputHouseNumber" name="inputHouseNumber" formControlName="houseNumber" placeholder="Hausnummer" required="" autocomplete="address-line2"/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.street">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('street').hasError(validation.type) && (emailForm.get('street').dirty || emailForm.get('street').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihre Straße an.</div>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.houseNumber">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('houseNumber').hasError(validation.type) && (emailForm.get('houseNumber').dirty || emailForm.get('houseNumber').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihre Hausnummer an.</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputFrom"class="offset-lg-1 col-lg-3 control-label margin-top-5">PLZ*/Ort*</label>
            <div class="col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputZip" name="inputZip" formControlName="zip" placeholder="Postleitzahl" required="" autocomplete="postal-code"/>
            </div>
            <div class="offset-lg-1 col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputCity" name="inputCity" formControlName="city" placeholder="Ort" required="" autocomplete="city"/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.zip">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('zip').hasError(validation.type) && (emailForm.get('zip').dirty || emailForm.get('zip').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihre Postleitzahl an.</div>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.city">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('city').hasError(validation.type) && (emailForm.get('city').dirty || emailForm.get('city').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihren Ort an.</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputEmail"class="offset-lg-1 col-lg-3 control-label margin-top-5">E-Mail*</label>
            <div class="col-lg-7 margin-top-5">
                <input type="email" class="form-control required" id="inputEmail" name="inputEmail" formControlName="email" placeholder="Email" required autocomplete="email"/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.email">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('email').hasError(validation.type) && (emailForm.get('email').dirty || emailForm.get('email').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihre E-Mail Adresse an.</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputEmailRepeat"class="offset-lg-1 col-lg-3 control-label margin-top-5">E-Mail Wiederholung*</label>
            <div class="col-lg-7 margin-top-5">
                <input type="email" class="form-control required" id="inputEmailRepeat" name="inputEmailRepeat" formControlName="emailRepeat" placeholder="Email" required autocomplete="email"/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.emailRepeat">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('emailRepeat').hasError(validation.type) && (emailForm.get('emailRepeat').dirty || emailForm.get('emailRepeat').touched)">
                <div class="alert alert-danger">Bitte geben Sie die selbe E-Mail Adresse wie im vorherigen Feld an.</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputPhone"class="offset-lg-1 col-lg-3 control-label margin-top-5">Telefon</label>
            <div class="col-lg-7 margin-top-5">
                <input type="tel" class="form-control" id="inputPhone" name="inputPhone" formControlName="phone" placeholder="Telefon" pattern="^[0-9]+$" autocomplete="tel"/>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputFrom"class="offset-lg-1 col-lg-3 control-label margin-top-5">gewünschte Anreise / Abreise</label>
            <div class="col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputFrom" name="inputFrom" placeholder="Von" formControlName="from"/>
            </div>
            <div class="offset-lg-1 col-lg-3 margin-top-5">
                <input type="text" class="form-control" id="inputTill" name="inputTill" placeholder="Bis" formControlName="until"/>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputSubject"class="offset-lg-1 col-lg-3 control-label margin-top-5">Betreff*</label>
            <div class="col-lg-7 margin-top-5">
                <input type="text" class="form-control required" id="inputSubject" name="inputSubject" formControlName="subject" placeholder="Betreff" required/>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.subject">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('subject').hasError(validation.type) && (emailForm.get('subject').dirty || emailForm.get('subject').touched)">
                <div class="alert alert-danger">Bitte geben Sie Ihrer Nachricht einen Betreff.</div>
            </div>
        </div>
        <div class="form-group row">
            <label for="inputText" class="offset-lg-1 col-lg-3 control-label margin-top-5">Nachricht*</label>
            <div class="col-lg-7 margin-top-5">
                <textarea class="form-control required" rows="6" id="inputText" name="inputText" formControlName="message" placeholder required></textarea>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.message">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('message').hasError(validation.type) && (emailForm.get('message').dirty || emailForm.get('message').touched)">
                <div class="alert alert-danger">Bitte geben Sie hier Ihre Nachricht an uns ein.</div>
            </div>
        </div>
        <div class="form-group row">
            <div class="offset-lg-4 col-lg-7 mobile-fix">
                <input class="form-check-input" type="checkbox" name="dsgvo_checkbox" id="dsgvo_checkbox" formControlName="dsgvo" required>
                <label for="dsgvo_checkbox">
                    Ich willige in die <a routerLink="/impressum">Datenschutzbestimmungen</a> ein. *
                </label>
            </div>
        </div>
        <div class="row margin-top-5" *ngFor="let validation of validation_messages.dsgvo">
            <div class="offset-lg-4 col-lg-7" *ngIf="emailForm.get('dsgvo').hasError(validation.type) && (emailForm.get('dsgvo').dirty || emailForm.get('dsgvo').touched)">
                <div class="alert alert-danger">Um Sie kontaktieren zu können benötigen wir Ihre Einwilligung.</div>
            </div>
        </div>
        <div class="row margin-top">
            <div class="offset-lg-1 col-lg-10">
                <div class="alert alert-success" role="alert" *ngIf="isEmailSended">Vielen Dank für Ihre Nachricht. Ihre Anfrage wurde
                    erfolgreich versendet.
                </div>
            </div>
            <div class="offset-lg-1 col-lg-10">
                <div class="alert alert-danger" role="alert" *ngIf="isEmailSended == false">Leider hat Ihre Kontaktaufnahme nicht funktioniert. Bitte prüfen Sie Ihre Daten oder rufen Sie uns gerne an.
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-lg-11 margin-top-5">
                <button type="submit" class="btn btn-primary float-right" [disabled]="!emailForm.valid">Absenden</button>
            </div>
        </div>
    </form>
    <div class="row margin-top">
        <div class="col-lg-12">
            <p class="text-center">
                Glashof<br>
                Wilfried und Angelika Müller<br>
                Glashofweg 6 <br>
                78112 St. Georgen<br><br>
                Telefon: <a href="tel:+4977246203">+49(0)7724/6203</a><br>
                Fax: +49(0)7724/948240<br>
                Email: <a href="mailto:glashof-mueller@t-online.de">glashof-mueller@t-online.de</a>
            </p>
        </div>
    </div>
</div>
