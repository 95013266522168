import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-playandfun',
  templateUrl: './playandfun.component.html',
  styleUrls: ['./playandfun.component.css']
})
export class PlayandfunComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
