import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aproach',
  templateUrl: './aproach.component.html',
  styleUrls: ['./aproach.component.css']
})
export class AproachComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
