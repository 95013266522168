<div class="footer">
    <div class="container">
      <div class="row">
        <div class="offset-lg-4 col-lg-5">
          <div class="footerContent">
            <ul class="footerContentList">
              <li><a routerLink="/kontakt">Kontakt</a></li>
              <li><a routerLink="/impressum">Impressum und<br>Datenschutzerklärung</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  
