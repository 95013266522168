import { Component } from '@angular/core';
import { ContactService } from '../contact.service';
import { Email } from '../email';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { CustomEmailValidator } from '../customemail.validator';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent {
    emailForm = new FormGroup({
        name: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        street: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        houseNumber: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        zip: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        city: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        email: new FormControl('', {validators: [Validators.required, Validators.email], updateOn: 'blur'}),
        emailRepeat: new FormControl('', {validators: [Validators.required, Validators.email], updateOn: 'blur'}),
        phone: new FormControl('',{}),
        from: new FormControl('',{}),
        until: new FormControl('',{}),
        subject: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        message: new FormControl('', {validators: Validators.required, updateOn: 'blur'}),
        dsgvo: new FormControl('', {validators: Validators.required})
    }, (formGroup: FormGroup) => {
        return CustomEmailValidator.areEqual(formGroup);
    });

    validation_messages = {
        'name': [
          { type: 'required', message: 'Bitte geben Sie Ihren Namen an.' },
        ],
        'street': [
          { type: 'required', message: 'Bitte geben Sie Ihre Straße an.' },
        ],
        'houseNumber': [
          { type: 'required', message: 'Bitte geben Sie Ihre Hausnummer an.' },
        ],
        'zip': [
          { type: 'required', message: 'Bitte geben Sie Ihre Postleitzahl an.' },
        ],
        'city': [
            { type: 'required', message: 'Bitte geben Sie Ihre Stadt an.' },
        ],
        'email': [
            { type: 'required', message: 'Bitte geben Sie Ihre E-Mail an, damit wir Sie kontaktieren können.' },
            { type: 'email', message: 'Bitte geben Sie eine valide E-Mail an.'}
        ],
        'emailRepeat': [
            { type: 'required', message: 'Bitte geben Sie die selbe E-Mail Adresse wie im vorherigen Feld an.' },
            { type: 'email', message: 'Bitte geben Sie die selbe E-Mail Adresse wie im vorherigen Feld an.'},
            { type: 'areEqual', message: 'Bitte geben Sie die selbe E-Mail Adresse wie im vorherigen Feld an.'}
        ],
        'subject': [
            { type: 'required', message: 'Bitte geben Sie Ihrer Nachricht einen Betreff.' },
        ],
        'message': [
            { type: 'required', message: 'Bitte geben Sie hier Ihre Nachricht an uns ein.' },
        ],
        'dsgvo': [
            { type: 'required', message: 'Um Sie kontaktieren zu können benötigen wir Ihre Einwilligung.' },
        ],
    };

  public emailRepeat: String;
  public isEmailSended: boolean;

  constructor(private _contactService: ContactService) {
      this.isEmailSended = null;
  }

  onSubmit(emailFormValue: any) {      
    if (this.emailForm.valid) {
        let _email: Email = new Email();
        _email.name = emailFormValue.name;
        _email.street = emailFormValue.street + " " + emailFormValue.houseNumber;
        _email.zip = emailFormValue.zip;
        _email.city = emailFormValue.city;
        _email.email = emailFormValue.email;
        _email.phone = emailFormValue.phone;
        _email.from = emailFormValue.from;
        _email.until = emailFormValue.until;
        _email.subject = emailFormValue.subject;
        _email.message = emailFormValue.message;

        this._contactService.postEmail(_email).subscribe(
            (value: any) => {
                this.extractData(value);
            }
        );
        
      }
  }

  private extractData(res: any) {
      if (res['status'] === "success") {
          this.isEmailSended = true;
          this.emailForm.reset();
      } else {
          this.isEmailSended = false;
      }
  }
}
