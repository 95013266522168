<div class="apartmentsHeader">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="introMessage">
            <h1 class="background-fix-h1">Wohnungen</h1>
            <h2 class="background-fix-h2">&nbsp;</h2>
            <h3 class="background-fix-h3">&nbsp;</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-apartmentshortview></app-apartmentshortview>
  <app-apartmentinformation></app-apartmentinformation>
  
