<div class="container-lg">
    <div class="row margin-top">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                    <div class="card-text">
                        <div class="row">
                            <div class="col-lg-6">
                                <h4>weitere Personen pro Übernachtung:</h4><br>
                                <table class="table">
                                    <tr>
                                        <td>Kinder unter zwei Jahren:</td>
                                        <td>kein Aufschlag</td>
                                    </tr>
                                    <tr>
                                        <td>Kinder von 2 bis 16 Jahren:</td>
                                        <td>8,00 Euro</td>
                                    </tr>
                                    <tr>
                                        <td>Personen ab 16 Jahren:</td>
                                        <td>15,00 Euro</td>
                                    </tr>
                                </table>
                            </div>
                            <div class="col-lg-6">
                                <ul>
                                    <li>
                                        <p>Die Kurtaxe beträgt pro Aufenthaltstag und je Person für Personen von 6 bis 15 Jahren 1,00€ und für Personen ab 16 Jahren 2,50€.<br>
                                        Kinder von 1. bis zum vollendeten 6. Lebensjahr sind von der Kurtaxe befreit. Für weitere Informationen siehe: <a href="https://www.st-georgen.de/site/St-Georgen-Responsive/get/params_E821782617/18770100/Kurtaxesatzung01.02.2018.pdf">Kurtaxesatzung</a>.</p>
                                    </li>
                                    <li>
                                        <p>Bitte beachten Sie, dass Wochenendaufenthalte von März bis Oktober nur noch kurzfristig buchbar
                                            sind. </p>
                                    </li>
                                    <li>
                                        <p>Bei Aufenthalten unter 4 Nächten fällt ein einmaliger Kurzzeitübernachtungszuschlag von 40,00€ an. Bitte beachten Sie, dass Sie Ihre Bettwäsche bei Übernachtungen unter 4 Nächten selbst mitbringen müssen.</p>
                                    </li>
                                    <li>
                                        <p>In unserem separaten Wirtschaftsraum steht Ihnen eine Waschmaschine
                                            sowie ein Trockner zur Verfügung.<br>
                                            Wir erlauben uns 3€ pro Maschinenbenutzung zu berechnen.</p>
                                    </li>
                                    <li>
                                        <p>Für Ihren Hund erlauben wir uns 7 Euro pro Übernachtung
                                            in Rechnung zu stellen.</p>
                                    </li>
                                    <li>
                                        <p>Eine Kartenzahlung ist leider nicht möglich.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="row margin-top">
                            <div class="col-lg-12">
                                <h4>Im Preis enthalten sind:</h4>

                                Bettwäsche (ab 4 Übernachtungen), Tischwäsche, Handtücher, Geschirrtücher, Kinderbett, Hochstuhl, Wäscheständer sowie Strom,
                                Wasser, Heizung und Endreinigung.<br><br>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>    