<div class="apartmentsHeader">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div *ngIf="_headerInfo" class="introMessage">
          <h1 class="background-fix-h1">{{_headerInfo.h1}}</h1>
          <h2 class="background-fix-h2">{{_headerInfo.h2}}</h2>
          <h3 class="background-fix-h3">{{_headerInfo.h3}}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="container" *ngIf="_texts">
  <div class="row margin-top bigger-font">
    <div class="col-lg-5 col-sm-6" *ngIf="_images">
      <img src="{{_images[0].imagePath}}" alt="Bergwiese" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
      <app-textcomponent [content]="_texts[0]"></app-textcomponent>
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images">
    <div class="col-lg-5 col-sm-6">
        <app-textcomponent [content]="_texts[1]"></app-textcomponent>
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
      <img src="{{_images[1].imagePath}}" alt="Wohnzimmer" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[2].imagePath}}" alt="Badezimmer" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
      <img src="{{_images[3].imagePath}}" alt="Küche" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[4].imagePath}}" alt="Schlafzimmer" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
      <img src="{{_images[5].imagePath}}" alt="Schlafzimmer" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[6].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[7]">
      <img src="{{_images[7].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[8]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[8].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[9]">
      <img src="{{_images[9].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[10]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[10].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[11]">
      <img src="{{_images[11].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[12]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[12].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[13]">
      <img src="{{_images[13].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[14]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[14].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[15]">
      <img src="{{_images[15].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[16]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[16].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[17]">
      <img src="{{_images[17].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[18]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[18].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[19]">
      <img src="{{_images[19].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
  <div class="row margin-top bigger-font" *ngIf="_images[20]">
    <div class="col-lg-5 col-sm-6">
      <img src="{{_images[20].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
    <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone" *ngIf="_images[21]">
      <img src="{{_images[21].imagePath}}" alt="Grundriss" class="img-rounded custom-image" width="100%">
    </div>
  </div>
<app-apartmentinformation></app-apartmentinformation>
<div class="container">
  <div class="row margin-top">
    <div class="col-lg-12">
      <div *ngIf="_apartment == 'bergwiese'" class="embed-responsive embed-responsive-1by1">
        <iframe class="embed-responsive-item" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038" title="Belegungskalender"><p>Ihr Browser kann das Kalender-Frame leider nicht anzeigen. Um den Kalender zu sehen klicken Sie bitte hier: <a href="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038">https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038</a></p></iframe>
      </div>
      <div *ngIf="_apartment == 'talaue'" class="embed-responsive embed-responsive-1by1">
        <iframe class="embed-responsive-item" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45037" title="Belegungskalender"><p>Ihr Browser kann das Kalender-Frame leider nicht anzeigen. Um den Kalender zu sehen klicken Sie bitte hier: <a href="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45037">https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038</a></p></iframe>
      </div>
      <div *ngIf="_apartment == 'waldblick'" class="embed-responsive embed-responsive-1by1">
        <iframe class="embed-responsive-item" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45036" title="Belegungskalender"><p>Ihr Browser kann das Kalender-Frame leider nicht anzeigen. Um den Kalender zu sehen klicken Sie bitte hier: <a href="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45036">https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038</a></p></iframe>
      </div>
      <div *ngIf="_apartment == 'sonnenstrahl'" class="embed-responsive embed-responsive-1by1">
        <iframe class="embed-responsive-item" frameborder="0" loading="lazy" referrerpolicy="no-referrer-when-downgrade" src="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45039" title="Belegungskalender"><p>Ihr Browser kann das Kalender-Frame leider nicht anzeigen. Um den Kalender zu sehen klicken Sie bitte hier: <a href="https://api.belegungskalender-kostenlos.de/kalender.php?kid=45039">https://api.belegungskalender-kostenlos.de/kalender.php?kid=45038</a></p></iframe>
      </div>
    </div>
  </div>
</div>
<br /><span style="font-size:9px;"><a href="https://www.belegungskalender-kostenlos.de/" rel="nofollow" style="font-size:9px;">FeWo Belegungskalender</a></span>