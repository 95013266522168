<div class="introHeader">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="introMessage">
                    <h1 class="background-fix-h1">Spielen & Freizeit</h1>
                    <h2 class="background-fix-h2">&nbsp;</h2>
                    <h3 class="background-fix-h3">&nbsp;</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container custom-images">
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/spielplatz.jpg" class="img-rounded custom-image" width="100%" >
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6">
            <h2 class="custom-header">Spielen & Freizeit im Sommer</h2><br>
            Ein großer Spielplatz mit Gartenhaus, Billard und Tischkicker sowie zwei Trampoline lassen Kinderherzen
            höher schlagen.<br>Drei Sandkästen, Schaukeln, ein Baumhaus, mehrere Rutschen und Tischtennis bieten eine große
            Abwechslung für die Freizeitgestaltung.<br><br>
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            Mit drei Grillplätzen rund um das Haus, einer Hollywoodschaukel, einem Fußballplatz und großen Grasflächen
            bietet die weitläufige und verkehrsferne Umgebung eine perfekte Möglichkeit für einen entspannten Urlaub.
            <br><br>
            Auf dem Hof steht eine große Auswahl an Kinderfahrzeugen für jedes Alter bereit.<br><br>

            In der Nähe befinden sich mehrere Freibäder und eine Minigolfanlage. Das Badeparadies Titisee, das TuWass in
            Rottweil und ein Kletterpark sind in kurzer Zeit zu erreichen. So steht auch der Freizeitgestaltung bei schlechtem
            Wetter nichts mehr im Weg.
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/spielhaus.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/kinderfahrzeuge.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/gartenhaus.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/spielplatz1.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/spielplatz2.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <h2 class="custom-header">Spielen & Freizeit im Winter</h2>
            Der Hang am Haus und die vorhandenen Bobs eignen sich
            hervorragend zum Rodeln. Skilifte sind in 10 - 15 Minuten zu erreichen.<br><br>

            Auch bieten die Spielscheune Unterkirnach und das Hallenbad im Winter und bei schlechterem Wetter ein Ausflugsziel.
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/housewinter2.jpg" class="img-rounded custom-image" width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/winter_spielhaus.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/winter_uebersicht.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
</div>