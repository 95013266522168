import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderData } from '../header-data';
import { ImageInfo } from '../image-info';

@Component({
  selector: 'app-apartmentdetail',
  templateUrl: './apartmentdetail.component.html',
  styleUrls: ['./apartmentdetail.component.css']
})
export class ApartmentdetailComponent implements OnInit {
  _headerInfo: HeaderData;
  _images: Array<ImageInfo>;
  _texts: Array<String>;
  _apartment: String;

  constructor(
    private _route: ActivatedRoute
  ) {
  }
  
  ngOnInit() {
    this._route.paramMap.subscribe(params => {
      this._apartment = params.get('id');
    });

    this._headerInfo = this._getHeaderDataMockFor(this._apartment);
    this._images = this._getImageInfosMockFor(this._apartment);
    this._texts = this._getTextInfosMockFor(this._apartment);
  }

  private _getHeaderDataMockFor(identifier: String): HeaderData {
    let headerData = new HeaderData();
    switch(identifier) {
      case "waldblick": {
        headerData.h1 = "Waldblick";
        break;
      }
      case "bergwiese" : {
        headerData.h1 = "Bergwiese";
        break;
      }
      case "talaue":{
        headerData.h1 = "Talaue";
        break;
      }
      case "sonnenstrahl": {
        headerData.h1 = "Sonnenstrahl";
        break;
      }
    }
    return headerData;
  }

  private _getImageInfosMockFor(identifier: String): Array<ImageInfo> {
    let images: Array<ImageInfo>;
    switch (identifier) {
      case "waldblick":{
        images = [
            new ImageInfo("assets/waldblick600.png"),
            new ImageInfo("assets/waldblick_wohnzimmer1.jpg"),
            new ImageInfo("assets/waldblick_wohnzimmer.jpg"),
            new ImageInfo("assets/waldblick_wohnzimmer2.jpg"),
            new ImageInfo("assets/waldblick_bad.jpg"),
            new ImageInfo("assets/waldblick_bad_2.jpg"),
            new ImageInfo("assets/waldblick_bad_3.jpg"),
            new ImageInfo("assets/waldblick_kueche.jpg"),
            new ImageInfo("assets/waldblick_kueche_1.jpg"),
            new ImageInfo("assets/waldblick_kueche2.jpg"),
            new ImageInfo("assets/waldblick_schlafzimmer1.jpg"),
            new ImageInfo("assets/waldblick_schlafzimmer2.jpg"),
            new ImageInfo("assets/waldblick_schlafzimmer3.jpg"),
            new ImageInfo("assets/waldblick_schlafzimmer4.jpg"),
            new ImageInfo("assets/waldblick_schlafzimmer5.jpg"),
            new ImageInfo("assets/waldblick_flur1.jpg"),
            new ImageInfo("assets/waldblick_flur2.jpg"),
            new ImageInfo("assets/grundrisswaldblick.png")
        ];
        break;
      }
      case "bergwiese":{
        images = [
          new ImageInfo("assets/bergwiese600.png"),
          new ImageInfo("assets/bergwiese_wohnzimmer2.jpg"),
          new ImageInfo("assets/bergwiese_bad.jpg"),
          new ImageInfo("assets/bergwiese_kueche.jpg"),
          new ImageInfo("assets/bergwiese_kueche2.jpg"),
          new ImageInfo("assets/bergwiese_schlafzimmer1.jpg"),
          new ImageInfo("assets/bergwiese_schlafzimmer2.jpg"),
          new ImageInfo("assets/grundrissbergwiese.png")
        ];
        break;
      }
      case "talaue":{
        images = [
            new ImageInfo("assets/talaue600.png"),
            new ImageInfo("assets/talaue_wohnzimmer.jpg"),
            new ImageInfo("assets/talaue_wohnzimmer2.jpg"),
            new ImageInfo("assets/talaue_kueche.jpg"),
            new ImageInfo("assets/talaue_schlafzimmer1.jpg"),
            new ImageInfo("assets/talaue_schlafzimmer2.jpg"),
            new ImageInfo("assets/talaue_schlafzimmer3.jpg"),
            new ImageInfo("assets/talaue_bad1.jpg"),
            new ImageInfo("assets/talaue_bad2.jpg"),
            new ImageInfo("assets/talaue_bad3.jpg"),
            new ImageInfo("assets/talaue_bad4.jpg"),
            new ImageInfo("assets/grundrisstalaue.png")
        ];
        break;
      }
      case "sonnenstrahl":{
        images = [
          new ImageInfo("assets/sonnenstrahl600.png"),
          new ImageInfo("assets/sonnenstrahl_wohnzimmer1.jpg"),
          new ImageInfo("assets/sonnenstrahl_wohnzimmer2.jpg"),
          new ImageInfo("assets/sonnenstrahl_wohnzimmer3.jpg"),
          new ImageInfo("assets/sonnenstrahl_wohnzimmer4.jpg"),
          new ImageInfo("assets/sonnenstrahl_bad.jpg"),
          new ImageInfo("assets/sonnenstrahl_kueche.jpg"),
          new ImageInfo("assets/sonnenstrahl_schlafzimmer1.jpg"),
          new ImageInfo("assets/sonnenstrahl_schlafzimmer2.jpg"),
          new ImageInfo("assets/sonnenstrahl_schlafzimmer21.jpg"),
          new ImageInfo("assets/sonnenstrahl_schlafzimmer3.jpg"),
          new ImageInfo("assets/sonnenstrahl_terasse.jpg"),
          new ImageInfo("assets/grundrisssonnenstrahl_1.png"),
          new ImageInfo("assets/grundrisssonnenstrahl_2.png")
        ];
        break;
      }
    }
    return images;
  }

  private _getTextInfosMockFor(identifier: String): Array<String>{
    let texts: Array<String>;
    switch(identifier) {
      case "bergwiese": {
        texts = [
          "<p><br> Auf 68 Quadratmeter können Sie sich in der behindertengerechten, voll ausgestatteten Wohnung mit zwei " +
          "Schlafzimmern entspannen.<br><br> Die Wohnung liegt im Erdgeschoss des dreistöckigen Hauses und verfügt über eine " +
          "Terrasse und einen Abstellraum.<br><br> Bettwäsche (ab 4 Übernachtungen) und Handtücher sind vorhanden und im Preis inklusive.</p>",

          "<p>In der Wohnung finden Sie unter anderem:<br><br></p><ul><li>eine Kaffeemaschine</li><li>einen Toaster</li><li>" +
          "viele Töpfe und Pfannen</li><li>einen Backofen</li><li>ausreichend Geschirr und Besteck für 10 Personen</li>" +
          "<li>einen Wasserkocher</li><li>einen Eierkocher</li><li>ein Handrührgerät</li><li>eine Spülmaschine</li></ul></p>"
        ];
        break;
      }
      case "talaue": {
        texts = [
            "<p><br>Erleben Sie Ihren Urlaub auf 75 Quadratmetern in der behindertengerechten Wohnung im Mittelgeschoss. <br><br>" +
            "Genießen Sie die Sonne auf dem großen Eckbalkon und erfreuen Sie sich an der schönen Aussicht.<br><br>Bettwäsche (ab 4 Übernachtungen) " +
            "und Handtücher sind vorhanden und im Preis inklusive.</p>",

          "<p>In der Wohnung finden Sie unter anderem:<br><br></p><ul><li>eine Kaffeemaschine</li><li>einen Toaster</li>" +
          "<li>viele Töpfe und Pfannen</li><li>einen Backofen</li><li>ausreichend Geschirr und Besteck für 10 Personen</li>" +
          "<li>einen Wasserkocher</li><li>einen Eierkocher</li><li>ein Handrührgerät</li><li>eine Spülmaschine</li></ul></p>"
        ];
        break;
      }
      case "sonnenstrahl": {
        texts = [
            "<p><br>Unsere größte Ferienwohnung mit 100 Quadratmetern bietet in einem geräumigen Wohnzimmer mit einem " +
            "Holzofen eine tolle Atmosphäre zum Wohlfühlen.<br><br>Über zwei Stockwerke bleibt in der Wohnung mit zwei " +
            "Bädern und drei Schlafzimmern genügend Platz zum entspannen.<br><br>Auch eine eigene Waschmaschine steht in " +
            "der Wohnung zu Ihrer Verfügung.<br><br>Bettwäsche (ab 4 Übernachtungen) und Handtücher sind vorhanden und im Preis inklusive.</p>",

            "<p>In der Wohnung finden Sie unter anderem:<br><br></p><ul><li>eine Kaffeemaschine</li><li>einen Toaster</li>" +
            "<li>viele Töpfe und Pfannen</li><li>einen Backofen</li><li>ausreichend Geschirr und Besteck für 10 Personen</li>" +
            "<li>einen Wasserkocher</li><li>einen Eierkocher</li><li>ein Handrührgerät</li><li>eine Spülmaschine</li></ul></p>"
        ];
        break;
      }
      case "waldblick": {
        texts = [
            "<p><br>In der gemütlichen Dachgeschosswohnung können Sie Ihren Urlaub genießen. Beobachten Sie vom eigenen " +
            "Balkon aus Tiere am Waldesrand oder erfreuen Sie sich an der schönen Landschaft.<br><br>Bettwäsche (ab 4 Übernachtungen) und " +
            "Handtücher sind vorhanden und im Preis inklusive.</p>",

            " <p>In der Wohnung finden Sie unter anderem:<br><br></p><ul><li>eine Kaffeemaschine</li><li>einen Toaster</li>" +
            "<li>viele Töpfe und Pfannen</li><li>einen Backofen</li><li>ausreichend Geschirr und Besteck für 10 Personen</li>" +
            "<li>einen Wasserkocher</li><li>einen Eierkocher</li><li>ein Handrührgerät</li><li>eine Spülmaschine</li></ul></p>"
        ];
        break;
      }
    }
    return texts;
  }

}
