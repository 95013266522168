<div class="apartmentsHeader">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="introMessage">
                    <h1 class="background-fix-h1">Hof & Tiere</h1>
                    <h2 class="background-fix-h2">&nbsp;</h2>
                    <h3 class="background-fix-h3">&nbsp;</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container custom-images">
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/hofvorne.jpg" class="img-rounded custom-image" width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6">
            <h2 class="custom-header">Unser lebender Bauernhof</h2><br>
            Auf unserem vollbewirtschafteten Bauernhof finden Sie stets offene Stalltüren. In unserer täglichen
            Stallbewirtschaftung können Sie und Ihre Kinder die Arbeit auf dem Bauernhof kennen lernen, Tiere füttern
            und streicheln.
            <br>
            Unsere Kühe werden im Sommer jeden Tag auf die Weide gebracht.
            <br><br>
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/kuehe.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/horses.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/horses2.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/rabbits2.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6" >
            <img src="../../assets/dog.jpg" class="img-rounded custom-image " width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/cat.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/kuehe2.jpg" class="img-rounded custom-image" width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/kuehe3.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/weiher.jpg" class="img-rounded custom-image" width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/landschaft1.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/landschaft2.jpg" class="img-rounded custom-image" width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/landschaft3.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5 col-sm-6">
            <img src="../../assets/weiher2.jpg" class="img-rounded custom-image" width="100%">
        </div>
        <div class="offset-lg-2 col-lg-5 col-sm-6 margin-top-when-phone">
            <img src="../../assets/weiher3.jpg" class="img-rounded custom-image " width="100%">
        </div>
    </div>
    <div class="row margin-top">
        <div class="col-lg-5">
            <img src="../../assets/eu.jpg" class="img-rounded custom-image" width="100%"> <br> <br>
            Weitere Informationen finden Sie auf der Webseite der <a href=" http://ec.europa.eu/agriculture/rural-development-2014-2020/index_de.htm" target="_blank">Europäischen Kommission</a>
        
        </div>
    </div>
</div>
